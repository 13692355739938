import axios from 'axios'

const RESOURCE_NAME = '/orders'

export default {
  getAll (params) {
    let apiParams = new URLSearchParams(window.location.search).toString()
    if (params) {
      apiParams = Object.entries(params).map(([key, val]) => `${key}=${val}`).join('&')
    }
    if (apiParams) {
      return axios.get(`${RESOURCE_NAME}?${apiParams.toString()}`)
    } else {
      return axios.get(`${RESOURCE_NAME}`)
    }
  },
  todayUndelivered () {
    return axios.get(`${RESOURCE_NAME}/today/undelivered`)
  },
  ordersInDelivery () {
    return axios.get(`${RESOURCE_NAME}/today/in-delivery`)
  },
  get (id) {
    return axios.get(`${RESOURCE_NAME}/${id}`)
  },
  cancelOrder (data) {
    return axios.post(`${RESOURCE_NAME}/cancel`, data)
  },
  store (data) {
    return axios.post(RESOURCE_NAME, data)
  },
  update (id, data) {
    return axios.put(`${RESOURCE_NAME}/${id}`, data)
  },
  deliveryGuys () {
    return axios.get(`${RESOURCE_NAME}/delivery-guys`)
  },
  assignDeliveryGuy (data) {
    return axios.post(`${RESOURCE_NAME}/delivery-guys/assign`, data)
  },
  getDeliveryAddressByPhoneNumber (phone) {
    return axios.get(`${RESOURCE_NAME}/addresses?phone=` + phone)
  },
  getBadReputationByPhoneNumber (phone) {
    return axios.get(`${RESOURCE_NAME}/bad-reputation?phone=` + phone)
  },
  getDeliveryTime (phone) {
    return axios.get(`${RESOURCE_NAME}/delivery-time`)
  },
  setDeliveryTime (data) {
    return axios.post(`${RESOURCE_NAME}/delivery-time`, data)
  }
}
